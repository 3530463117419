/**药品元数据上传 */
import request from '@/utils/request'

// 药品元元素上传列表查询-卡片类型
export function recordCardListApi(data) {
  return request({
    url: '/dm/drug/upload/record/list',
    method: 'get',
    params: data
  })
}
// 删除已上传的记录
export function deleteRecordApi(data) {
  return request({
    url: '/dm/drug/upload/' + data.recordId,
    method: 'delete'
  })
}
// 撤回
export function recallRecordApi(data) {
  return request({
    url: '/dm/drug/upload/rollback/' + data.recordId,
    method: 'PUT'
  })
}
// 终止任务
export function terminationRecordApi(data) {
  return request({
    url: '/dm/drug/upload/termination/' + data.recordId,
    method: 'PUT'
  })
}
// 根据记录ID获取记录信息
export function getRecordDetailApi(data) {
  return request({
    url: '/dm/drug/upload/info/' + data.recordId,
    method: 'get'
  })
}
// 下载excel
export function downloadExcelApi(data) {
  return request({
    url: `/dm/common/template/download/${data.mouldName}`,
    method: 'get',
    resopnseReturn: true,
    responseType: 'blob'
  })
}
// 上传excel
export function uploadApi(data) {
  return request({
    insideError: false,
    url: '/dm/drug/upload',
    method: 'post',
    data: data,
    loading: false
  })
}
// 上传文件有误，点此下载明细
export function downloadDetailsApi(data) {
  return request({
    url: '/dm' + data.url,
    method: 'get',
    responseType: 'blob',
    resopnseReturn: true
  })
}
// 药品相关Excel重新上传-相关内容只能在已撤回状态下
export function reUploadApi(data) {
  return request({
    url: '/dm/drug/upload/reUpload',
    method: 'post',
    data: data
  })
}