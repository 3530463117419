<template>
  <div class="app-container">
    <el-row class="headerClass">
      <el-col :span="4" style="border-left: 5px solid #0073E9;padding-left: 10px;">上传文档预处理</el-col>
      <el-col :span="20">
        <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">
          <el-form-item label='' prop="searchValue">
            <el-input v-model="queryParams.searchValue"
                      placeholder="ID/药品名称/商品名/生产厂家/批准文号"
                      clearable
                      style="width: 300px;height: 36px!important;line-height: 36px;"/>
          </el-form-item>
          <el-form-item label="停用状态:" prop="deleteFlag">
            <el-select
              v-model="queryParams.deleteFlag"
              placeholder="停用状态"
              clearable
              size="small"
              class="statusClass"
              style="width: 100px"
            >
              <el-option label="全部" value=""/>
                <el-option
                  v-for="item in deleteFlagOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  {{item.label}}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="药品类型:" prop="type">
            <el-select
              v-model="queryParams.type"
              placeholder="类别"
              clearable
              size="small"
              class="statusClass"
              style="width: 100px"
            >
              <el-option label="全部" value=""/>
              <el-option
                v-for="item in typeOptions"
                :key="item"
                :label="item"
                :value="item"
              >
                {{item}}
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">查询</el-button>
<!--            <el-button size="small" @click="()=>{this.$router.go(0)}">重载</el-button>-->
            <el-button size="small" @click="handleAdd" class="addClass" v-if="isInvalid == true">
              <svg-icon icon-class="chuli" style="width: 24px;height: 16px;"/>
              处理文档
            </el-button>
            <el-button icon="el-icon-circle-plus-outline" size="small" @click="columnSettings">设置</el-button>
            <el-button type="primary" size="small" @click="handleSubmit" v-if="isInvalid == true">提交</el-button>
            <el-button type="danger" size="small" @click="rejectFn" v-if="isInvalid == true">驳回上传</el-button>
            <el-button size="small" @click="handleReback" class="addClass">返回</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
<!--    :header-cell-style="{'text-align':'center'}"-->
<!--    :cell-style="{'text-align':'center'}"-->
    <el-table
      ref="udpTable"
      v-loading="loading"
      border
      stripe
      :height="tableHeight"
      :data="roleList">
      <el-table-column
        fixed="left"
        label="停用标志"
         width="100"
         align="center"
         header-align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.deleteFlag === '0'">在用</span>
          <span v-else-if="scope.row.deleteFlag === '1'">停用</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="data in columnOptions"
        :key="data.id"
        :prop="data.prop"
        :label="data.name"
        :fixed="data.fixed"
        :resizable="false"
        :sortable="data.sortable"
        :min-width="data.width"
        :align="data.align"
      >
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="ustdCode"-->
<!--        label="编号"-->
<!--        width="180">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="genericName"-->
<!--        label="药品名称">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="tradeName"-->
<!--        label="商品名">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="manufacturerName"-->
<!--        label="生产厂家">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="specText"-->
<!--        label="规格">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="doseUnit"-->
<!--        label="单位">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="formName"-->
<!--        label="剂型">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="className"-->
<!--        label="类别">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--        prop="approvalNo"-->
<!--        label="批准文号">-->
<!--      </el-table-column>-->
      <template slot="empty" v-if="isResult === false">
        <img src="@/assets/images/no-booking.svg" alt="">
        <p>暂无数据</p>
      </template>
      <template slot="empty" v-else>
        <img src="@/assets/images/no-left-data.svg" alt="">
        <p>未查询到结果，请修改查询条件重试！</p>
      </template>
    </el-table>
    <el-row>
      <el-col :span="12" class="stateContent">
        <span>上传时间：{{ uploadTime }}</span>
        <span>状态：<i class="stateResult">{{ statusStr }}</i></span>
        <span>上传方式：<i class="uploadMethod">{{ uploadTypeStr }}</i></span>
      </el-col>
      <el-col :span="12">
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </el-col>
    </el-row>
    <el-dialog title="处理文档"
               :visible.sync="uploadTemplete"
               width="430px"
               center
               :before-close="handleClose">
      <div v-show="uploadDataName === '' || uploadDataName === null || uploadDataName === undefined">
        <el-row>
          <el-col>
            <el-button type="text" @click="downDocument">下载机构药品元数据</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-upload
              ref="uploadExcel"
              :limit="1"
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
              :http-request="uploadFile"
              :show-file-list="true"
              :file-list="fileList"
              :auto-upload="true"
              class="upload-demo"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              multiple>
              <svg-icon icon-class="shangchuanwendang" style="width:40px;height:44px;color:#4D78F5;margin: 20px 0px 5px;"/>
              <div class="el-upload__text">将文件拖到此处上传</div>
              <div class="el-upload__text">或者，您可以单击此处选择一个文件</div>
              <div class="el-upload__text"><em>点击上传</em></div>
            </el-upload>
          </el-col>
        </el-row>
      </div>
      <div v-show="uploadDataName">
        <el-row>
          <el-col>
            <el-input
              v-model="uploadDataName"
              disabled
              clearable
              style="width: 300px;"
              @change="uploadDataNameChange"/>
            <i class="el-icon-remove" @click.prevent="delUploadDataName"></i>
          </el-col>
        </el-row>
        <el-row v-show="isShowError">
          <el-col>
            <el-button class="download-btn" type="text" @click="onDownloadDetails">上传文件有误，点此下载明细</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="text-align: center;margin:40px 0px 20px;">
            <el-button type="primary" @click="submitDocument" v-if="isSubmit === false">开始校验并上传</el-button>
            <el-button type="primary" :loading="isSubmit" v-else>校验中请稍后</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
<!--    预处理提交按钮点击之后的对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleCloseSumbmit">
      <span style="display:inline-block;line-height: 24px;"><i class="el-icon-warning" style="font-size: 24px;color: #E6A23C;margin-right: 5px;float: left"></i>上传文档预处理确认提交, 是否继续?</span>
      <el-row>
        <el-col style="text-align: center;margin:20px 0px 0px;">
          <el-button size="small" @click="handleCloseSumbmit">取 消</el-button>
          <el-button size="small" type="primary" @click="preprocessingCommit" :loading="isConfirm">确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { download,upload,list,head, submit,type,reject } from "@/api/dm/uploadDocumentPretreatment"
// import { getConcept } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { UCgetConfig } from '@/utils/columnSettings'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import {
  downloadDetailsApi
} from '@/api/dm/drugMetadataUpload/drugMetadataUpload'
import {
  onStartDownload
} from '@/utils/downloadFile'
import { Message } from "element-ui";

export default {
  name: "drugMetadataMatchingPretreatment",
  data() {
    return {
      isInvalid: true,
      isShowError: false, // 上传文件有误
      downloadDetailsUrl: '',
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      statusStr: '',
      uploadTime: '',
      uploadTypeStr: '',
      isResult: false,
      uploadTemplete: false,
      conceptConst,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      // showSearch: true,
      // 总条数
      total: 0,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      menuExpand: false,
      menuNodeAll: false,
      deptExpand: true,
      deptNodeAll: false,
      // 日期范围
      dateRange: [],
      // 状态数据字典
      typeOptions: [],
      // 停用标志数据字典
      deleteFlagOptions: [
        {
          value: '0',
          label: '在用',
        },
        {
          value: '1',
          label: '停用',
        }
      ],
      // 数据范围选项
      dataScopeOptions: [
        {
          value: "1",
          label: "全部数据权限"
        },
        {
          value: "2",
          label: "自定数据权限"
        },
        {
          value: "3",
          label: "本部门数据权限"
        },
        {
          value: "4",
          label: "本部门及以下数据权限"
        },
        {
          value: "5",
          label: "仅本人数据权限"
        }
      ],
      // 服务包类型
      packTypeOptions: [
        {
          value: "1",
          label: "医疗机构"
        },
        {
          value: "2",
          label: "卫计委"
        },
        {
          value: "3",
          label: "药企"
        },
        {
          value: "4",
          label: "其他"
        }
      ],
      // 菜单列表
      menuOptions: [],
      // 部门列表
      deptOptions: [],
      // 查询参数
      queryParams: {
        deleteFlag: '0',
        pageNum: 1,
        pageSize: 10,
        searchValue: '',
        type: '',
        recordId: ''
      },
      // 表单参数
      form: {},
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      // 表单校验
      rules: {
        packName: [
          { required: true, message: "服务包名称不能为空", trigger: "blur" }
        ],
        packType: [
          { required: true, message: "分类不能为空", trigger: "change" }
        ],
        status: [
          { required: true, message: "状态不能为空", trigger: "change" }
        ]
      },
      uploadData: [],
      uploadDataName: '',
      fileList: [],
      isSubmit: false,
      recordId: '',
      sltConfigKey: '', // 药品元数据管理-上传文档预处理-表格配置
      sltConfigClassify: '',
      // searchValue: '',
      columnOptions: [],
      refundForm: {
        refundTableData: []
      },
      options: [
        {
          id: 1,
          prop: 'orgDrugId',
          name: 'ID',
          fixed: false,
          show: true,
          // sortable: 'custom',
          sortable: false,
          align: 'center',
          width: '150'
        },
        {
          id: 2,
          prop: 'ustdCode',
          name: '统编码',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '150'
        },
        {
          id: 3,
          prop: 'nhsaCode',
          name: '国家贯标码',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '150'
        },
        {
          id: 4,
          prop: 'classCode',
          name: '药品类型代码',
          fixed: false,
          show: true,
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          id: 5,
          prop: 'className',
          name: '药品类型',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 6,
          prop: 'genericName',
          name: '通用名',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '300'
        },
        {
          id: 7,
          prop: 'tradeName',
          name: '商品名',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 8,
          prop: 'specText',
          name: '规格',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 9,
          prop: 'formCode',
          name: '剂型代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 10,
          prop: 'formName',
          name: '剂型',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 11,
          prop: 'phaClassCode',
          name: '分类代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 12,
          prop: 'phaClassName',
          name: '分类',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 13,
          name: '最小单位代码',
          prop: 'minPackUnitCode',
          sortable: false,
          show: true,
          fixed: false,
          width: '100',
          minWidth: '',
          align: 'center'
        },
        {
          id: 14,
          prop: 'minPackUnit',
          name: '最小单位',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 15,
          prop: 'doseUnitCode',
          name: '规格单位代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 16,
          prop: 'doseUnit',
          name: '规格单位',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 17,
          prop: 'doseFactor',
          name: '规格系数',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 18,
          prop: 'packUnitCode',
          name: '包装单位代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 19,
          prop: 'packUnit',
          name: '包装单位',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 20,
          prop: 'packFactor',
          name: '包装系数',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 21,
          prop: 'manufacturerCode',
          name: '厂家代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 22,
          prop: 'manufacturerName',
          name: '厂家名称',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '300'
        },{
          id: 23,
          prop: 'approvalNo',
          name: '批准文号',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },{
          id: 24,
          prop: 'nedFlag',
          name: '国基标志',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 25,
          prop: 'shNedFlag',
          name: '上基标志',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 26,
          prop: 'highRiskLvCode',
          name: '高危药品分级代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '150'
        },
        {
          id: 27,
          prop: 'highRiskLvName',
          name: '高危药品分级',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 28,
          prop: 'antibioticLvCode',
          name: '抗菌药分级代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '150'
        },
        {
          id: 29,
          prop: 'antibioticLvName',
          name: '抗菌药分级',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 30,
          prop: 'toxicNarcoticTypeCode',
          name: '精麻毒放标志代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '150'
        },
        {
          id: 31,
          prop: 'toxicNarcoticTypeName',
          name: '精麻毒放标志',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 32,
          prop: 'dddValue',
          name: 'DDD值',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 33,
          prop: 'dddUnitCode',
          name: 'DDD单位代码',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 34,
          prop: 'dddUnit',
          name: 'DDD单位',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '100'
        },
        {
          id: 35,
          prop: 'ncpFlag',
          name: '国家采集药品标志',
          fixed: false,
          show: true,
          sortable:  false,
          align: 'center',
          width: '130'
        }
      ],
      dialogVisible:false,
      isConfirm: false
    };
  },
  created() {
    let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 41)
    this.sltConfigKey = item.configKey // 药品元数据管理-上传文档预处理-表格配置
    this.sltConfigClassify = item.configClassify // 配置分类
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        //页面初始化完毕，默认触发一次按照更新时间排序
        this.$refs.udpTable.sort('id','ascending');
      })
    })
    const recordId = this.$route.params && this.$route.params.recordId
    // console.log(recordId)
    this.recordId = recordId
    if (this.recordId) {
      // console.log(this.recordId)
      this.getList();
      //获取类别集合
      // let recordId =
      type(this.recordId).then(result => {
        this.typeOptions = result.data
      })
      this.headMessage()
    } else {
      // console.log('recordId暂无')
    }
    const isInvalid = this.$route.query && this.$route.query.isInvalid
    if (isInvalid) {
      this.isInvalid = isInvalid
    } else {
      this.isInvalid = true
    }
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    let height = this.$refs.udpTable.offsetHeight
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 100 - 100
      })()
    }

  },
  methods: {
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 41)
      this.sltConfigKey = item.configKey //-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.options
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.udpTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 41)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.options,
        _this.getSettingTable
      )
    },
    // 校验文件格式是否为excel    
    checkFileType(){
      const name = this.uploadDataName
      const suffix = name.substr(name.lastIndexOf('.'))
      if (name === '') {
        this.$message.warning('选择需要导入的Excel文件或json文件！')
        return false
      }
      if (suffix !== '.xls' && suffix !== '.xlsx') {
        this.$message.warning('文件错误，请重新检查')
        return false
      }
      return true
    },
    submitDocument() {
      this.isSubmit = true
      let checkFlag = this.checkFileType()
      if (!checkFlag) {
        this.isSubmit = false
        // this.delUploadDataName()
        return
      }
      // console.log(this.uploadData[0])
      const fileObj = this.uploadData[0]
      // FormData 对象
      const form = new FormData()
      // 文件对象
      form.append('file', fileObj)
      form.append('recordId', this.recordId)
      upload(form).then(res => {
        this.isSubmit = false
        // console.log(res)
        if (res.code === 200) {
          // console.log(res)
          this.$message.success('文件：' + fileObj.name + '上传成功')
          this.delUploadDataName() //
          this.uploadTemplete = false
          this.handleQuery() // 刷新table页面
        } else if (res.code === 18888) {
          this.isShowError = true
          this.downloadDetailsUrl = res.msg
        }
      }).catch(err => {
        this.isSubmit = false
      })
    },
    // 下载明细
    async onDownloadDetails() {
      const obj = { url: this.downloadDetailsUrl}
      let res = await downloadDetailsApi(obj)
      // console.log(res)
      await onStartDownload(res)
    },
    // 下载文档
    async downDocument() {
      // console.log(this.recordId)
      // let recordId = '1'
      let res = await download(this.recordId)
      // console.log(res)
      this.download(res)
    },

    // function downloadHandle(res) {
    //   console.log(res)
    //   console.log(res.headers)
    //   let downloadName = decodeURI(res.headers['filename'])
    //   let url = window.URL.createObjectURL(new Blob([res.data]))
    //   let link = document.createElement('a')
    //   link.style.display = 'none'
    //   link.href = url
    //   link.setAttribute('download', downloadName)
    //   document.body.appendChild(link)
    //   link.click()
    //   Message({
    //     message: '下载成功',
    //     type: 'success'
    //   })
    // }

    // 下载文件
    download (res) {
      if (!res) {
        return
      }
      console.log(res)
      console.log(res.headers)
      let downloadName = decodeURI(res.headers['filename'])
      let url = window.URL.createObjectURL(new Blob([res.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', downloadName)

      document.body.appendChild(link)
      link.click()
    },
    // 上传文件个数超过定义的数量
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，请删除后继续上传`)
    },
    // 删除上传文件
    handleRemove(file) {
      // console.log(file)
      for(let i = 0; i < this.uploadData.length; i++){
        if(file.uid == this.uploadData[i].uid){
          this.uploadData.splice(i,1)
        }
      }
      // console.log(this.uploadData)
      this.uploadDataName = this.uploadData[0].name
    },
    // 上传文件
    uploadFile (item) {
      // console.log(item)
      this.uploadData.push(item.file)
      // console.log(this.uploadData)
      this.uploadDataName = this.uploadData[0].name
    },
    // 根据记录ID获取记录头信息
    async headMessage() {
      // console.log(this.recordId)
      let res = await head(this.recordId)
      this.statusStr = res.data.statusStr
      this.uploadTime = res.data.uploadTime
      this.uploadTypeStr = res.data.uploadTypeStr
      // console.log(res)
    },
    /** 上传预处理列表查询 */
    getList() {
      this.loading = true;
      this.queryParams.recordId = this.recordId
      list(this.queryParams).then(
        response => {
          // // console.log(response.data.rows)
          this.roleList = response.data.rows;
          this.total = response.data.total;
          this.loading = false;
        }
      );
    },
    delUploadDataName() {
      this.uploadDataName = ''
      this.$refs.uploadExcel.clearFiles()
      this.uploadData = []
      this.isShowError = false
      // console.log(this.uploadData)
    },
    // change选择excel表格
    uploadDataNameChange(val) {
      // console.log(val)
    },
    /** 查询菜单树结构 */
    async getMenuTreeselect() {

      let response = await menuTreeselect()
      if (response.code === 200) {
        // // console.log(response)
        this.menuOptions = [];
        const menu = { menuId: 0, menuName: '主类目', children: [] };
        menu.children = this.handleTree(response.data, "menuId");
        // // console.log(menu)
        // // console.log(menu.children)
        this.menuOptions = menu.children
        // console.log(this.menuOptions)
      }
    },
    /** 查询部门树结构 */
    getDeptTreeselect() {
      deptTreeselect().then(response => {
        this.deptOptions = response.data;
      });
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    // 所有部门节点数据
    getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      let checkedKeys = this.$refs.dept.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = this.$refs.dept.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    /** 根据角色ID查询菜单树结构 */
    getRoleMenuTreeselect(packId) {
      return roleMenuTreeselect(packId).then(response => {
        this.menuOptions = response.data.menus;
        return response;
      });
    },
    /** 根据角色ID查询部门树结构 */
    getRoleDeptTreeselect(roleId) {
      return roleDeptTreeselect(roleId).then(response => {
        this.deptOptions = response.data.depts;
        return response;
      });
    },
    // 角色状态修改
    handleStatusChange(row) {
      let text = row.status === '0' ? "启用" : "停用";
      this.$confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return changeRoleStatus(row.roleId, row.status);
      }).then(() => {
        this.msgSuccess(text + "成功");
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0';
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 取消按钮（数据权限）
    cancelDataScope() {
      this.openDataScope = false;
      this.reset();
    },
    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      this.menuExpand = false,
        this.menuNodeAll = false,
        this.deptExpand = true,
        this.deptNodeAll = false,
        this.form = {
          packId: undefined,
          packName: undefined,
          packType: '1',
          status: '0',
          menuIdList: [],
          menuCheckStrictly: true,
          deptCheckStrictly: true,
          remark: undefined
        };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1;
      this.getList();
      //获取类别集合
      type(this.recordId).then(result => {
        // console.log(result)
        this.typeOptions = result.data
      })
    },
    handleCloseSumbmit() {
      if (this.isConfirm) {
        this.$message.warning('数据正在提交，请稍后再执行当前操作！')
        return false
      }
      this.dialogVisible = false
      this.loading = false
    },
    // 预处理确定提交
    async preprocessingCommit() {
      this.isConfirm = true
      let _this = this
      let res = await submit(this.recordId)
        if (res.code === 200) {
          this.loading = false
          this.isConfirm = false
          this.dialogVisible = false
          // console.log(res)
          _this.$message.success(res.msg)
          _this.$store.dispatch('tagsView/delView', this.$route)
          await _this.$router.push('/dm/drugMetadataMatching')
        } else {
          this.loading = false
          this.isConfirm = false
          this.dialogVisible = false
          // this.$message.warning(res.msg)
        }
    },
    // 点击提交
    async handleSubmit() {
      this.loading = true
      this.dialogVisible = true
      this.isConfirm = false
    },
    // async handleSubmit() {
    //   let _this = this
    //   this.loading = true
    //   this.$confirm('上传文档预处理确认提交, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(async () => {
    //     let res = await submit(this.recordId)
    //     if (res.code === 200) {
    //       this.loading = false
    //       // console.log(res)
    //       _this.$message.success(res.msg)
    //       _this.$store.dispatch('tagsView/delView', this.$route)
    //       await _this.$router.push('/dm/drugMetadataMatching')
    //     } else {
    //       this.loading = false
    //       // this.$message.warning(res.msg)
    //     }
    //   }).catch(() => {
    //     this.loading = false
    //   });
    //   // await this.$router.push('/dm/drugMetadataMatching')
    //   // console.log(this.recordId)
    //   // let recordId = '1'
    // },
    async rejectFn(){
      let _this = this
      this.loading = true
      this.$confirm('上传文档预处理即将驳回上传, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await reject(this.recordId)
        if (res.code === 200) {
          this.loading = false
          // console.log(res)
          _this.$message.success(res.msg)
          _this.$store.dispatch('tagsView/delView', this.$route)
          await _this.$router.push('/dm/drugMetadataMatching')
        } else {
          this.loading = false
          this.$message.warning(res.msg)
        }
      }).catch(() => {
        this.loading = false
      });
    },
    handleReback() {
      this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push('/dm/drugMetadataMatching')
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // // 多选框选中数据
    // handleSelectionChange(selection) {
    //   this.ids = selection.map(item => item.roleId)
    //   this.single = selection.length!=1
    //   this.multiple = !selection.length
    // },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      if (type == 'menu') {
        let treeList = this.menuOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.menu.store.nodesMap[treeList[i].menuId].expanded = value;
        }
      } else if (type == 'dept') {
        let treeList = this.deptOptions;
        for (let i = 0; i < treeList.length; i++) {
          this.$refs.dept.store.nodesMap[treeList[i].menuId].expanded = value;
        }
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      if (type == 'menu') {
        this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
      } else if (type == 'dept') {
        this.$refs.dept.setCheckedNodes(value ? this.deptOptions: []);
      }
    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      if (type == 'menu') {
        this.form.menuCheckStrictly = value ? true: false;
      } else if (type == 'dept') {
        this.form.deptCheckStrictly = value ? true: false;
      }
    },
    /** 处理文档按钮操作 */
    handleAdd() {
      this.uploadTemplete = true
      // this.$router.push('/drugGroup/edit/')
    },
    // 关闭处理文档按钮
    handleClose() {
      this.delUploadDataName()
      this.uploadTemplete = false
    }
  }
};
</script>
<style lang="scss" scoped>
.stateContent{
  padding-top: 10px;
  span {
    //width: 213px;
    //height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
    margin-right: 10px;
    .stateResult{
      color: #E4860A;
      font-style: normal;
    }
    .uploadMethod{
      color: #333333;
      font-style: normal;
    }
  }
}
/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}
.download-btn {
  color: #ED4014;
}
</style>
