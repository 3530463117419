var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { staticClass: "headerClass" },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                "border-left": "5px solid #0073E9",
                "padding-left": "10px",
              },
              attrs: { span: 4 },
            },
            [_vm._v("上传文档预处理")]
          ),
          _c(
            "el-col",
            { attrs: { span: 20 } },
            [
              _c(
                "el-form",
                {
                  ref: "queryForm",
                  staticStyle: { float: "right" },
                  attrs: { model: _vm.queryParams, inline: true },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "searchValue" } },
                    [
                      _c("el-input", {
                        staticStyle: {
                          width: "300px",
                          height: "36px!important",
                          "line-height": "36px",
                        },
                        attrs: {
                          placeholder: "ID/药品名称/商品名/生产厂家/批准文号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.queryParams.searchValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "searchValue", $$v)
                          },
                          expression: "queryParams.searchValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "停用状态:", prop: "deleteFlag" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "statusClass",
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "停用状态",
                            clearable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryParams.deleteFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "deleteFlag", $$v)
                            },
                            expression: "queryParams.deleteFlag",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._l(_vm.deleteFlagOptions, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "药品类型:", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "statusClass",
                          staticStyle: { width: "100px" },
                          attrs: {
                            placeholder: "类别",
                            clearable: "",
                            size: "small",
                          },
                          model: {
                            value: _vm.queryParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "type", $$v)
                            },
                            expression: "queryParams.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._l(_vm.typeOptions, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item,
                                attrs: { label: item, value: item },
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "small",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleQuery()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm.isInvalid == true
                        ? _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: { size: "small" },
                              on: { click: _vm.handleAdd },
                            },
                            [
                              _c("svg-icon", {
                                staticStyle: { width: "24px", height: "16px" },
                                attrs: { "icon-class": "chuli" },
                              }),
                              _vm._v(" 处理文档 "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            size: "small",
                          },
                          on: { click: _vm.columnSettings },
                        },
                        [_vm._v("设置")]
                      ),
                      _vm.isInvalid == true
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleSubmit },
                            },
                            [_vm._v("提交")]
                          )
                        : _vm._e(),
                      _vm.isInvalid == true
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: { click: _vm.rejectFn },
                            },
                            [_vm._v("驳回上传")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "addClass",
                          attrs: { size: "small" },
                          on: { click: _vm.handleReback },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "udpTable",
          attrs: {
            border: "",
            stripe: "",
            height: _vm.tableHeight,
            data: _vm.roleList,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              fixed: "left",
              label: "停用标志",
              width: "100",
              align: "center",
              "header-align": "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.deleteFlag === "0"
                      ? _c("span", [_vm._v("在用")])
                      : scope.row.deleteFlag === "1"
                      ? _c("span", [_vm._v("停用")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.columnOptions, function (data) {
            return _c("el-table-column", {
              key: data.id,
              attrs: {
                prop: data.prop,
                label: data.name,
                fixed: data.fixed,
                resizable: false,
                sortable: data.sortable,
                "min-width": data.width,
                align: data.align,
              },
            })
          }),
          _vm.isResult === false
            ? _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ])
            : _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-left-data.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
              ]),
        ],
        2
      ),
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "stateContent", attrs: { span: 12 } }, [
            _c("span", [_vm._v("上传时间：" + _vm._s(_vm.uploadTime))]),
            _c("span", [
              _vm._v("状态："),
              _c("i", { staticClass: "stateResult" }, [
                _vm._v(_vm._s(_vm.statusStr)),
              ]),
            ]),
            _c("span", [
              _vm._v("上传方式："),
              _c("i", { staticClass: "uploadMethod" }, [
                _vm._v(_vm._s(_vm.uploadTypeStr)),
              ]),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "处理文档",
            visible: _vm.uploadTemplete,
            width: "430px",
            center: "",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadTemplete = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.uploadDataName === "" ||
                    _vm.uploadDataName === null ||
                    _vm.uploadDataName === undefined,
                  expression:
                    "uploadDataName === '' || uploadDataName === null || uploadDataName === undefined",
                },
              ],
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.downDocument },
                        },
                        [_vm._v("下载机构药品元数据")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "uploadExcel",
                          staticClass: "upload-demo",
                          attrs: {
                            limit: 1,
                            "on-exceed": _vm.handleExceed,
                            "on-remove": _vm.handleRemove,
                            "http-request": _vm.uploadFile,
                            "show-file-list": true,
                            "file-list": _vm.fileList,
                            "auto-upload": true,
                            drag: "",
                            action:
                              "https://jsonplaceholder.typicode.com/posts/",
                            multiple: "",
                          },
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "40px",
                              height: "44px",
                              color: "#4D78F5",
                              margin: "20px 0px 5px",
                            },
                            attrs: { "icon-class": "shangchuanwendang" },
                          }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("将文件拖到此处上传"),
                          ]),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("或者，您可以单击此处选择一个文件"),
                          ]),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _c("em", [_vm._v("点击上传")]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.uploadDataName,
                  expression: "uploadDataName",
                },
              ],
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: { disabled: "", clearable: "" },
                        on: { change: _vm.uploadDataNameChange },
                        model: {
                          value: _vm.uploadDataName,
                          callback: function ($$v) {
                            _vm.uploadDataName = $$v
                          },
                          expression: "uploadDataName",
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-remove",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.delUploadDataName($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowError,
                      expression: "isShowError",
                    },
                  ],
                },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "download-btn",
                          attrs: { type: "text" },
                          on: { click: _vm.onDownloadDetails },
                        },
                        [_vm._v("上传文件有误，点此下载明细")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "text-align": "center",
                        margin: "40px 0px 20px",
                      },
                    },
                    [
                      _vm.isSubmit === false
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.submitDocument },
                            },
                            [_vm._v("开始校验并上传")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.isSubmit },
                            },
                            [_vm._v("校验中请稍后")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleCloseSumbmit,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            { staticStyle: { display: "inline-block", "line-height": "24px" } },
            [
              _c("i", {
                staticClass: "el-icon-warning",
                staticStyle: {
                  "font-size": "24px",
                  color: "#E6A23C",
                  "margin-right": "5px",
                  float: "left",
                },
              }),
              _vm._v("上传文档预处理确认提交, 是否继续?"),
            ]
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "center",
                    margin: "20px 0px 0px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleCloseSumbmit },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        loading: _vm.isConfirm,
                      },
                      on: { click: _vm.preprocessingCommit },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }